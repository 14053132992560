import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';



function ScatterPlotOutlinedIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -3 24 24">
<path d="M12.186,4.377c0-1.381,1.119-2.5,2.5-2.5l0,0c1.381,0,2.5,1.119,2.5,2.5l0,0c0,1.379-1.119,2.498-2.5,2.498l0,0
	C13.305,6.875,12.186,5.756,12.186,4.377L12.186,4.377z M13.186,4.377c0.002,0.825,0.673,1.498,1.5,1.5l0,0
	c0.827-0.002,1.498-0.675,1.5-1.5l0,0c-0.002-0.827-0.673-1.5-1.5-1.502l0,0C13.858,2.877,13.188,3.55,13.186,4.377L13.186,4.377z"
	/>
<path d="M8.809,4.999c-1.166,0-2.204-0.816-2.447-1.997l0,0C6.329,2.836,6.311,2.665,6.311,2.499l0,0
	c0-1.166,0.816-2.207,1.999-2.447l0,0C8.478,0.017,8.646,0,8.813,0l0,0c1.164,0,2.202,0.816,2.447,1.999l0,0L10.77,2.1l0.49-0.099
	c0.034,0.169,0.051,0.337,0.051,0.502l0,0c-0.002,1.164-0.818,2.202-2,2.446l0,0c-0.159,0.033-0.318,0.05-0.475,0.05l0,0
	C8.827,4.999,8.818,4.999,8.809,4.999L8.809,4.999z M8.511,1.03C7.799,1.174,7.31,1.802,7.31,2.499l0,0
	c0,0.096,0.011,0.197,0.033,0.302l0,0C7.484,3.51,8.113,4,8.809,4l0,0c0.101,0,0.202-0.009,0.303-0.029l0,0
	c0.707-0.146,1.198-0.773,1.198-1.468l0,0c0-0.101-0.009-0.2-0.028-0.303l0,0H10.28c-0.146-0.711-0.772-1.201-1.468-1.201l0,0
	C8.714,0.999,8.613,1.01,8.511,1.03L8.511,1.03z"/>
<path d="M11.249,15.533c0-1.382,1.118-2.501,2.499-2.501l0,0c1.381,0,2.501,1.119,2.501,2.501l0,0c0,1.379-1.12,2.498-2.501,2.498
	l0,0C12.367,18.031,11.249,16.912,11.249,15.533L11.249,15.533z M12.248,15.533c0.002,0.825,0.673,1.496,1.5,1.498l0,0
	c0.827-0.002,1.498-0.673,1.5-1.498l0,0c-0.002-0.828-0.673-1.501-1.5-1.503l0,0C12.921,14.032,12.25,14.705,12.248,15.533
	L12.248,15.533z"/>
<path d="M5.999,11.032c0-1.382,1.119-2.499,2.499-2.501l0,0c1.38,0.002,2.499,1.119,2.499,2.501l0,0c0,1.38-1.119,2.499-2.499,2.499
	l0,0C7.118,13.531,5.999,12.412,5.999,11.032L5.999,11.032z M6.999,11.032c0.002,0.825,0.673,1.497,1.499,1.5l0,0
	c0.827-0.003,1.499-0.675,1.501-1.5l0,0c-0.002-0.827-0.674-1.5-1.501-1.502l0,0C7.672,9.532,7.001,10.205,6.999,11.032
	L6.999,11.032z"/>
<path d="M0,14.406c0-1.381,1.117-2.5,2.5-2.5l0,0c1.379,0,2.498,1.119,2.498,2.5l0,0c0,1.381-1.119,2.5-2.498,2.5l0,0
	C1.117,16.906,0,15.787,0,14.406L0,14.406z M0.998,14.406C1,15.233,1.671,15.904,2.5,15.906l0,0c0.825-0.002,1.498-0.673,1.5-1.5
	l0,0c-0.002-0.827-0.675-1.498-1.5-1.5l0,0C1.671,12.908,1,13.579,0.998,14.406L0.998,14.406z"/>
    </SvgIcon>
  );
}

export default ScatterPlotOutlinedIcon;


