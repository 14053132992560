import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';



function ScatterPlotOutlinedIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -3 24 24">
<circle cx="8.75" cy="2.5" r="2.5"/>
<circle cx="14.875" cy="4.25" r="2.5"/>
<circle opacity="0.25" cx="13.75" cy="15.5" r="2.5"/>
<circle opacity="0.25" cx="8.625" cy="10.75" r="2.5"/>
<circle opacity="0.25" cx="2.5" cy="14.125" r="2.5"/>
<path d="M11.281,15.268c0-1.382,1.118-2.501,2.499-2.501l0,0c1.381,0,2.501,1.119,2.501,2.501l0,0c0,1.379-1.12,2.498-2.501,2.498
	l0,0C12.399,17.766,11.281,16.646,11.281,15.268L11.281,15.268z M12.28,15.268c0.002,0.825,0.673,1.496,1.5,1.498l0,0
	c0.827-0.002,1.498-0.673,1.5-1.498l0,0c-0.002-0.828-0.673-1.501-1.5-1.503l0,0C12.953,13.767,12.282,14.439,12.28,15.268
	L12.28,15.268z"/>
<path d="M6.031,10.767c0-1.382,1.119-2.499,2.499-2.501l0,0c1.38,0.002,2.499,1.119,2.499,2.501l0,0c0,1.38-1.119,2.499-2.499,2.499
	l0,0C7.15,13.266,6.031,12.146,6.031,10.767L6.031,10.767z M7.031,10.767c0.002,0.825,0.673,1.497,1.499,1.5l0,0
	c0.827-0.003,1.499-0.675,1.501-1.5l0,0c-0.002-0.827-0.674-1.5-1.501-1.502l0,0C7.704,9.267,7.033,9.939,7.031,10.767L7.031,10.767
	z"/>
<path d="M0.032,14.141c0-1.381,1.117-2.5,2.5-2.5l0,0c1.379,0,2.498,1.119,2.498,2.5l0,0c0,1.381-1.119,2.5-2.498,2.5l0,0
	C1.149,16.641,0.032,15.521,0.032,14.141L0.032,14.141z M1.03,14.141c0.002,0.827,0.673,1.498,1.502,1.5l0,0
	c0.825-0.002,1.498-0.673,1.5-1.5l0,0c-0.002-0.827-0.675-1.498-1.5-1.5l0,0C1.703,12.643,1.032,13.313,1.03,14.141L1.03,14.141z"/>
    </SvgIcon>
  );
}

export default ScatterPlotOutlinedIcon;


