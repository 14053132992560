import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';



function CurvesIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -3 24 24">
<path d="M9.524,17.988c-2.704-0.345-8.007-3.729-9.261-6.857l0,0C0.086,10.669,0,10.218,0,9.793l0,0
	c0.125-2.534,2.582-3.186,3.435-4.556l0,0c1.092-1.469,1.233-4.391,4.38-5.208l0,0L7.928,0L8.04,0.046
	c0.02,0.015,3.121,1.252,4.879,2.846l0,0c1.738,1.577,3.085,4.023,3.081,7.246l0,0C15.91,13.523,12.584,17.883,9.758,18l0,0
	C9.681,18,9.603,17.998,9.524,17.988L9.524,17.988z M4.099,5.986c-1.266,1.634-3.336,2.52-3.21,3.807l0,0
	c0,0.232,0.045,0.509,0.177,0.858l0,0c0.814,2.406,6.266,6.063,8.526,6.221l0,0c0.052,0.004,0.105,0.006,0.166,0.006l0,0
	c1.025,0.016,2.424-0.985,3.508-2.387l0,0c1.094-1.379,1.86-3.151,1.846-4.354l0,0c-0.004-2.813-1.14-4.893-2.712-6.334l0,0
	C11.025,2.541,8.448,1.4,7.879,1.162l0,0C5.351,1.888,5.468,3.965,4.099,5.986L4.099,5.986z M7.768,1.117l0.137-0.537L7.768,1.117
	L7.768,1.117z"/>
<path d="M10.021,14.622c-1.717-0.24-4.903-2.148-5.758-4.117l0,0c-0.117-0.299-0.179-0.6-0.179-0.892l0,0
	c0.014-0.894,0.529-1.401,0.962-1.775l0,0C5.5,7.458,5.979,7.125,6.219,6.792l0,0C6.815,6.057,6.97,4.184,8.967,3.693l0,0
	l0.107-0.03l0.107,0.044c0.021,0.011,1.899,0.725,3.008,1.687l0,0c1.085,0.943,1.951,2.443,1.948,4.417l0,0
	c-0.016,1.133-0.592,2.246-1.333,3.172l0,0c-0.75,0.908-1.683,1.629-2.627,1.645l0,0C10.13,14.628,10.079,14.626,10.021,14.622
	L10.021,14.622z M6.87,7.558C6.465,8.098,5.937,8.429,5.543,8.77l0,0C5.13,9.118,4.96,9.39,4.973,9.613l0,0
	c0,0.091,0.017,0.211,0.088,0.39l0,0c0.401,1.251,3.739,3.444,5.025,3.499l0,0c0.026,0,0.058,0.002,0.093,0.002l0,0
	c0.549,0.016,1.376-0.536,2.007-1.326l0,0c0.646-0.773,1.079-1.788,1.063-2.366l0,0c-0.002-1.542-0.644-2.677-1.563-3.493l0,0
	c-0.77-0.683-2.238-1.326-2.652-1.495l0,0C7.629,5.233,7.756,6.274,6.87,7.558L6.87,7.558z M8.921,4.78l0.133-0.537L8.921,4.78
	L8.921,4.78z"/>
<path d="M10.586,10.998c-0.551,0.04-1.035-0.599-1.082-1.426c-0.047-0.827,0.359-1.53,0.91-1.57c0.551-0.04,1.035,0.599,1.082,1.426
	C11.543,10.254,11.137,10.958,10.586,10.998z"/>
    </SvgIcon>
  );
}

export default CurvesIcon;


