/*!

=========================================================
* SciLicium Platform v0.0.1
=========================================================

* Copyright 2021 SciLicium (https://www.scilicium.com)

* Coded by SciLicium
* Author: Thomas Darde

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//import RGVroutes from './routes/rgv'
import Hudecaroutes from './routes/hudeca'


var routes = Hudecaroutes;
export default routes;
